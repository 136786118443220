/**
 * Acc지원 정보 - 투자유치 현황 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccInvestInfoDto {
  constructor(obj) {
    this.invest_no = obj.invest_no;
    this.acc_no = obj.acc_no;
    this.invest_date = obj.invest_date;
    this.invest_org = obj.invest_org;
    this.invest_amount = obj.invest_amount;
    this.invest_round = obj.invest_round;
    this.display_order = obj.display_order || -1;
  }
}
