/**
 * Acc지원 내역 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120123 Front.Acc지원프로그램마이페이지조회}
 */
export default class AccMyApplyDto {
  constructor(obj) {
    this.accNo = obj.acc_no;
    this.accPgmMngNo = obj.acc_pgm_mng_no;
    this.accKind = obj.acc_kind;
    this.accName = obj.acc_name;
    this.codeApplyStatus = obj.code_apply_status;
    this.writeCompleteTime = obj.write_complete_time;
    this.applyCancelDate = obj.apply_cancel_date;
    this.accPath = obj.acc_path;
    this.accAdditionKind = obj.acc_addition_kind;
    this.applicationYn = obj.application_yn === 'Y';

    // this.etcFile2Yn = obj.etc_file2_yn === 'Y';
    // this.etcFile2 = obj.etc_file2;
    // this.etcFileName2 = obj.etc_file_name2;
    // this.etcFileTitle2 = obj.etc_file_title2;
    // this.etcFileNote2 = obj.etc_file_note2;
    // this.etcInput2Yn = obj.etc_input2_yn === 'Y';
    // this.etcInput2 = obj.etc_input2;
    // this.etcInputTitle2 = obj.etc_input_title2;
    // this.etcInputNote = obj.etc_input_note;
    // this.etcInputNote2 = obj.etc_input_note2;

    // this.ceoCellphoneFirst = obj.ceo_cellphone_first;
    // this.ceoCellphoneSec = obj.ceo_cellphone_sec;
  }
}
