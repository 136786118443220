import { APIs } from '@/common/portalApi';
import AccApplyDto from '../dto/AccApplyDto';
import AccApplyPgmMngDetailDto from '../dto/AccApplyPgmMngDetailDto';
import AccMyApplyDto from '../dto/AccMyApplyDto';
import AccInvestInfoDto from '../dto/apply/AccInvestInfoDto';
import ApplyStatus from '../enum/ApplyStatus';

/**
 * Programs > 공모지원 & 공모지원내역 Service
 */
export default class ProgramsApplicationService {
  /**
   * 생성자
   * @param {function(import('axios').AxiosRequestConfig): import('axios').AxiosPromise} apiClient
   */
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  /**
   * Acc지원프로그램 관리 목록 조회
   * @param {string} accKind Acc 타입
   * @returns {Promise<AccApplyPgmMngDetailDto[] | never>}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120112 Front.Acc지원프로그램관리조회}
   */
  getAccApplySpecialPgmMng(accKind) {
    return this.apiClient({
      url: APIs.ACC_APPLY_PGM_MNG_LIST,
      data: {
        acc_display: 'Y',
        acc_kind: accKind,
        acc_name: '해커톤',
      },
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return data.map((item) => new AccApplyPgmMngDetailDto(item));
        }
        return [];
      });
  }

  getAccApplyPgmMngList(accKind) {
    return this.apiClient({
      // url: APIs.ACC_APPLY_PGM_MNG_LIST,
      url: `/api2/competitions`,
      data: {
        size: 0,
        order: 'desc',
        data: {
          isShow: 1,
          state: 1,
          isAlwaysRecruitment: 1
        },
        acc_display: 'Y',
        acc_kind: accKind,
      },
    })
      .then(({ data, status }) => {
        console.log(data)
        if (status === 200) {
          return data.list.map((item) => new AccApplyPgmMngDetailDto(item));
        }
        return [];
      });
  }

  /**
   * Acc지원프로그램 관리 상세 조회
   * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
   * @returns {Promise<AccApplyPgmMngDetailDto | never>}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22119700 Front.Programs.ACC.조회}
   */
  getAccApplyPgmMngDetail(accPgmMngNo) {
    return this.apiClient({
      url: `/api2/competition/${accPgmMngNo}`,
      method: 'get'
    })
    .then(({ data, status }) => {
      if (status === 200) {
        return data.data;
      }
      return [];
    });
  }

  getAccApplyPgmMngItemList(accPgmMngNo) {
    return this.apiClient({
      url: `/api2/competition/${accPgmMngNo}/items`,
      method: 'get'
    })
    .then(({ data, status }) => {
      if (status === 200) {
        return data.list;
      }
      return [];
    });
  }

  /**
   * Acc지원 정보 조회
   * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
   * @param {number} accNo 지원 고유번호
   * @param {string} codeApplyStatus 지원 상태코드
   * @returns {Promise<AccApplyDto | never>}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
   */
  getAccApply(accPgmMngNo, accNo, codeApplyStatus) {
    return this.apiClient({
      url: APIs.ACC_APPLY_ACC_INFO,
      data: {
        acc_pgm_mng_no: accPgmMngNo,
        acc_no: accNo,
        code_apply_status: codeApplyStatus,
      },
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return new AccApplyDto(data);
        }
        return new AccApplyDto({});
      });
  }

  /**
   * Acc지원 정보 저장
   * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
   * @param {string} codeApplyStatus 지원 상태코드
   * @param {AccApplyDto} accApply 지원 정보
   * @returns {import('axios').AxiosPromise}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120185 Front.Acc액셀러레이팅메인 정보저장}
   */
  saveAccApply(accPgmMngNo, codeApplyStatus, accApply) {
    const {
      accInfo, accCeoInfo, accAwardCareerInfo, accPropertyInfo, accLicenseInfo,
      accStartPgJoinExp, accInvestInfo, accMemberInfo, accMentorInfo,
    } = accApply;

    accInfo.acc_pgm_mng_no = accPgmMngNo;
    accInfo.code_apply_status = codeApplyStatus;

    return this.apiClient({
      url: APIs.ACC_APPLY_INS_ACC_INFO,
      data: {
        accInfo,
        accCeoInfo: accCeoInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
        accAwardCareerInfo: accAwardCareerInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
        accPropertyInfo: accPropertyInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
        accLicenseInfo: accLicenseInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
        accStartPgJoinExp: accStartPgJoinExp.map((info, index) => ({ ...info, display_order: index + 1 })),
        accInvestInfo: (
          accInfo.invest_history_check === '1' // 투자입력 없음인 경우 투자유치 현황 없음
            ? [new AccInvestInfoDto({})]
            : accInvestInfo
        ).map((info, index) => ({ ...info, display_order: index + 1 })),
        accMemberInfo: accMemberInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
        accMentorInfo: accMentorInfo.map((info, index) => ({ ...info, display_order: index + 1 })),
      },
    });
  }

  /**
   * Acc지원 임시저장 정보 조회
   * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
   * @returns {import('axios').AxiosPromise}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120183 Front.Acc액셀러레이팅임시 정보조회}
   * @see {@link https://wiki.dpteam.net/display/DPGN/Error+Code Error Code}
   */
  getTempAccApply(accPgmMngNo) {
    return this.apiClient({
      url: APIs.ACC_APPLY_TEMPORARY,
      data: {
        acc_pgm_mng_no: accPgmMngNo,
        code_apply_status: ApplyStatus.draft,
      },
    });
  }

  
  getTempAccApplyMember(accPgmMngNo, memberId) {
    return this.apiClient({
      url: `/api2/competition/response/answer`,
      data: {
        id: accPgmMngNo,
        memberId: memberId
      },
    })
    .then(({ data, status }) => {
      if (status === 200) {
        console.log('getTempAccApplyMember', status, data.data)
        return data.data;
      }
      return [];
    });
  }

  getAccApplyPgmMngItemAnserList(accPgmMngNo, memberId) {
    return this.apiClient({
      url: `/api2/competition/response/${accPgmMngNo}/${memberId}`,
      method: 'get'
    })
    .then(({ data, status }) => {
      if (status === 200) {
        console.log('getAccApplyPgmMngItemAnserList', status, data.data)
        return data.data;
      }
      return [];
    });
  }

  /**
   * Acc지원 정보 임시저장 삭제
   * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
   * @param {number} accNo 지원 고유번호
   * @returns {import('axios').AxiosPromise}
   */
  deleteTempAccApply(accPgmMngNo, accNo) {
    return this.apiClient({
      url: APIs.ACC_DELETE_TEMPORARY,
      data: {
        acc_pgm_mng_no: accPgmMngNo,
        acc_no: accNo,
        code_apply_status: ApplyStatus.draft,
      },
    });
  }

  /**
   * Acc지원 내역 조회
   * @returns {Promise<AccMyApplyDto[] | never>}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120123 Front.Acc지원프로그램마이페이지조회}
   */
  getAccMyApplyList(id, memberId) {
    return this.apiClient({
      url: `/api2/competition/responsesList`,
      data: {
        memberId: memberId
      }
    })
    .then(({ data, status }) => {
      if (status === 200) {
        return data.list;
      }
      return [];
    });
  }
  getAccMyApplyListORI() {
    return this.apiClient({
      url: APIs.MYPAGE_PROGRAM_LIST,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return data.map((item) => new AccMyApplyDto(item));
        }
        return [];
      });
  }

  /**
   * Acc지원 취소
   * @param {number} accNo 지원 고유번호
   * @returns {import('axios').AxiosPromise}
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120194 Front.Acc액셀러레이팅메인 정보취소}
   */
  cancelAccMyApply(accPgmMngNo, memberId) {
    return this.apiClient({
      url: `/api2/competition/state`,
      data: {
        id: accPgmMngNo,
        memberId: memberId,
        isState: 2
      },
    })
  }
  cancelAccMyApplyORI(accNo) {
    return this.apiClient({
      url: APIs.MYPAGE_PROGRAM_CANCEL,
      data: {
        acc_no: accNo,
      },
    });
  }
}
