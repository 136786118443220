import AccInfoDto from './apply/AccInfoDto';
import AccCeoInfoDto from './apply/AccCeoInfoDto';
import AccAwardCareerInfoDto from './apply/AccAwardCareerInfoDto';
import AccPropertyInfoDto from './apply/AccPropertyInfoDto';
import AccLicenseInfoDto from './apply/AccLicenseInfoDto';
import AccMemberInfoDto from './apply/AccMemberInfoDto';
import AccStartPgJoinExpDto from './apply/AccStartPgJoinExpDto';
import AccMentorInfoDto from './apply/AccMentorInfoDto';
import AccInvestInfoDto from './apply/AccInvestInfoDto';

/**
 * Acc지원 정보 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccApplyDto {
  constructor(obj) {
    /**
     * 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {AccInfoDto}
     */
    this.accInfo = obj.accInfo
      ? new AccInfoDto(obj.accInfo)
      : new AccInfoDto({});

    /**
     * 회사정보 - 대표자, 공동대표
     * @type {AccCeoInfoDto[]}
     */
    this.accCeoInfo = obj.accCeoInfo
      ? obj.accCeoInfo.map((info) => new AccCeoInfoDto(info))
      : [new AccCeoInfoDto({})];

    /**
     * 회사정보 - 수상경력
     * @type {AccAwardCareerInfoDto[]}
     */
    this.accAwardCareerInfo = obj.accAwardCareerInfo
      ? obj.accAwardCareerInfo.map((info) => new AccAwardCareerInfoDto(info))
      : [new AccAwardCareerInfoDto({})];

    /**
     * 회사정보 - 보유 지적 재산권
     * @type {AccPropertyInfoDto[]}
     */
    this.accPropertyInfo = obj.accPropertyInfo
      ? obj.accPropertyInfo.map((info) => new AccPropertyInfoDto(info))
      : [new AccPropertyInfoDto({})];

    /**
     * 회사정보 - 보유 허가 및 등록 현황
     * @type {AccLicenseInfoDto[]}
     */
    this.accLicenseInfo = obj.accLicenseInfo
      ? obj.accLicenseInfo.map((info) => new AccLicenseInfoDto(info))
      : [new AccLicenseInfoDto({})];

    /**
     * 멤버정보 - 멤버
     * @type {AccMemberInfoDto[]}
     */
    this.accMemberInfo = obj.accMemberInfo
      ? obj.accMemberInfo.map((info) => new AccMemberInfoDto(info))
      : [new AccMemberInfoDto({})];

    /**
     * 멤버정보 - 창업 프로그램 참여경험
     * @type {AccStartPgJoinExpDto[]}
     */
    this.accStartPgJoinExp = obj.accStartPgJoinExp
      ? obj.accStartPgJoinExp.map((info) => new AccStartPgJoinExpDto(info))
      : [new AccStartPgJoinExpDto({})];

    /**
     * 멤버정보 - 멘토
     * @type {AccMentorInfoDto[]}
     */
    this.accMentorInfo = obj.accMentorInfo
      ? obj.accMentorInfo.map((info) => new AccMentorInfoDto(info))
      : [new AccMentorInfoDto({})];

    /**
     * 투자내용
     * @type {AccInvestInfoDto[]}
     */
    this.accInvestInfo = obj.accInvestInfo
      ? obj.accInvestInfo.map((info) => new AccInvestInfoDto(info))
      : [new AccInvestInfoDto({})];
  }
}
