/**
 * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등) DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
 export default class AccInfoDto {
  constructor(obj) {
    this.acc_no = obj.acc_no;

    this.acc_pgm_mng_no = obj.acc_pgm_mng_no;

    this.company_name = obj.company_name;
    this.company_name_en = obj.company_name_en;

    this.company_reg_no = obj.company_reg_no;
    this.corporation_reg_no = obj.corporation_reg_no;
    this.venture_cert_no = obj.venture_cert_no;

    this.build_year = obj.build_year;
    this.build_month = obj.build_month;
    this.build_day = obj.build_day;

    // this.ceo_name = obj.ceo_name;
    // this.co_ceo_name1 = obj.co_ceo_name1;
    // this.co_ceo_name2 = obj.co_ceo_name2;

    this.employee_number = obj.employee_number;
    this.employee_add_tag = obj.employee_add_tag;

    this.company_homepage_url = obj.company_homepage_url;
    this.company_facebook_url = obj.company_facebook_url;
    this.company_insta_url = obj.company_insta_url;
    this.service_name = obj.service_name;
    this.service_introduce = obj.service_introduce;
    this.service_diffrence = obj.service_diffrence;
    this.service_url = obj.service_url;
    this.service_facebook_url = obj.service_facebook_url;

    this.promotion_url1 = obj.promotion_url1;
    this.promotion_url2 = obj.promotion_url2;
    this.promotion_url3 = obj.promotion_url3;

    this.com_reg_no_file = obj.com_reg_no_file;
    this.com_reg_no_file_name = obj.com_reg_no_file_name;
    this.stockholder_file = obj.stockholder_file;
    this.stockholder_file_name = obj.stockholder_file_name;
    this.register_file = obj.register_file;
    this.register_file_name = obj.register_file_name;
    this.property_file = obj.property_file;
    this.property_file_name = obj.property_file_name;
    // this.venture_file = obj.venture_file;
    // this.venture_file_name = obj.venture_file_name;
    this.etc_file = obj.etc_file;
    this.etc_file_name = obj.etc_file_name;
    this.etc_file2 = obj.etc_file2;
    this.etc_file_name2 = obj.etc_file_name2;

    /**
     * 원하는 지원 항목
     * - 일반적인 경우에 '5단계 : 기타입력'에서 입력
     * - (AC114) Corporate ACC-Hyundai인 경우에 '5단계 : 기타입력'에서 입력
     * @type {string}
     */
    this.code_support_item = obj.code_support_item || '000000000';

    /**
     * 원하는 지원 항목
     * - (AC107) Corporate ACC-Eland인 경우에 '5단계 : 기타입력'에서 입력
     * - (AC114) Corporate ACC-Hyundai인 경우에 '5단계 : 기타입력'에서 입력
     * @type {string}
     */
    this.code_support_corporateitem = obj.code_support_corporateitem;

    /**
     * 원하는 지원 항목
     * - (AC108) GEP (Global Expansion Program)인 경우에 '5단계 : 기타입력'에서 입력
     * @type {string}
     */
    this.code_support_gepitem = obj.code_support_gepitem;

    this.support_plan = obj.support_plan;
    this.support_path = obj.support_path;
    this.support_path_sub = obj.support_path_sub;

    this.etc_input = obj.etc_input;
    this.etc_input2 = obj.etc_input2;
    this.etc_input3 = obj.etc_input3;
    this.etc_input4 = obj.etc_input4;
    this.etc_input5 = obj.etc_input5;

    this.entry_agree = obj.entry_agree === '1' ? '1' : '0';

    this.code_apply_status = obj.code_apply_status;

    this.write_complete_time = obj.write_complete_time;
    this.write_member_id = obj.write_member_id;

    this.application_category = obj.application_category;

    this.member_configuration = obj.member_configuration;
    this.member_configuration_input = obj.member_configuration_input;

    this.ceo_cellphone_first = obj.ceo_cellphone_first;
    this.ceo_cellphone_sec = obj.ceo_cellphone_sec;

    this.ceo_email = obj.ceo_email;
    this.ceo_final_enducation = obj.ceo_final_enducation;
    this.ceo_major_career = obj.ceo_major_career;
    this.us_corp_est_yn = obj.us_corp_est_yn;

    /**
     * 투자이력 없음 여부
     * - (AC114) Corporate ACC-Hyundai인 경우에 '4단계 : 투자내용'에서 입력
     * @type {string}
     */
    this.invest_history_check = obj.invest_history_check === '1' ? '1' : '0';

    /**
     * 지원영역
     * - (AC102) Lifestyle-Digital Healthcare / Wellness인 경우에 '5단계 : 기타입력'에서 입력
     * @type {string}
     */
    this.acc_addition_kind = obj.acc_addition_kind;

    /**
     * 체크박스
     */
    this.check_developer = (obj.NUMBER_DEVELOPER && obj.NUMBER_DEVELOPER !== '0');
    this.check_director = (obj.NUMBER_DIRECTOR && obj.NUMBER_DIRECTOR !== '0');
    this.check_designer = (obj.NUMBER_DESIGNER && obj.NUMBER_DESIGNER !== '0');
    console.log(obj.NUMBER_DEVELOPER);
    console.log(obj.NUMBER_DIRECTOR);
    console.log(obj.NUMBER_DESIGNER);
    // 개발자
    this.NUMBER_DEVELOPER = obj.NUMBER_DEVELOPER | 0;
    // 기획자
    this.NUMBER_DIRECTOR = obj.NUMBER_DIRECTOR | 0;
    // 디자이너
    this.NUMBER_DESIGNER = obj.NUMBER_DESIGNER | 0;
  }
}
