/**
 * 공모 지원 상태
 */
export default class ApplyStatus {
  /**
   * 지원완료
   */
  static get completed() {
    return 'AC290';
  }

  /**
   * 지원취소
   */
  static get cancelled() {
    return 'AC291';
  }

  /**
   * 합격
   */
  static get passed() {
    return 'AC292';
  }

  /**
   * 불합격
   */
  static get failed() {
    return 'AC293';
  }

  /**
   * 임시저장
   */
  static get draft() {
    return 'AC299';
  }
}
