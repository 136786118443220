/**
 * Acc지원프로그램 관리 상세 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120112 Front.Acc지원프로그램관리조회}
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22119700 Front.Programs.ACC.조회}
 */
 export default class AccApplyPgmMngDetailDto {
  constructor(obj) {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     * @type {number}
     */
    // this.accPgmMngNo = obj.acc_pgm_mng_no;
    this.accPgmMngNo = obj.id

    /**
     * ACC지원타입 (ACC 프로그램)
     * @type {string}
     */
    // this.accKind = obj.acc_kind;
    this.accKind = 'AC101';

    /**
     * 지원프로그램 관리명
     * @type {string}
     */
    // this.accName = obj.acc_name;
    this.accName = obj.title;

    /**
     * 모집 시작일자
     * @type {string}
     */
    // this.startDate = obj.start_date;
    this.startDate = obj.startDate;
    /**
     * 모집 종료일자
     * @type {string}
     */
    // this.endDate = obj.end_date;
    this.endDate = obj.endDate;

    /**
     * 합격발표일시 / 합격발표 방법
     * @type {string}
     */
    // this.resultNotiContent = obj.result_noti_content;
    this.resultNotiContent = obj.announcementOfPass;

    /**
     * 모집 시작일자
     * @type {string}
     */
    // this.postStartDate = obj.post_start_date;
    this.postStartDate = obj.startDate;
    /**
     * 모집 시작시간
     * @type {string}
     */
    // this.postStartTime = obj.post_start_time;
    this.endDate = obj.startTime;

    /**
     * 모집 종료일자
     * @type {string}
     */
    // this.postEndDate = obj.post_end_date;
    this.endDate = obj.endDate;
    /**
     * 모집 종료시간
     * @type {string}
     */
    // this.postEndTime = obj.post_end_time;
    this.endDate = obj.endTime;

    /**
     * 상시모집 유무
     * @type {boolean}
     */
    // this.applicationYn = obj.application_yn === 'Y';
    this.applicationYn = obj.isAlwaysRecruitment === 1;


    /** 회사정보 ***************************************** */

    /**
     * 업체명 국문 유무
     * @type {boolean}
     */
    this.companyNameYn = obj.company_name_yn === 'Y';
    /**
     * 업체명 영문 유무
     * @type {boolean}
     */
    this.companyNameEnYn = obj.company_name_en_yn === 'Y';

    /**
     * 사업자등록번호 유무
     * @type {boolean}
     */
    this.companyRegNoYn = obj.company_reg_no_yn === 'Y';
    /**
     * 법인 등록번호 유무
     * @type {boolean}
     */
    this.corporationRegNoYn = obj.corporation_reg_no_yn === 'Y';
    /**
     * 벤처기업 인증번호 유무
     * @type {boolean}
     */
    this.ventureCertNoYn = obj.venture_cert_no_yn === 'Y';
    /**
     * 설립연도 유무
     * @type {boolean}
     */
    this.buildYearYn = obj.build_year_yn === 'Y';

    /**
     * 대표자(이름) 유무
     * @type {boolean}
     */
    this.ceoNameYn = obj.ceo_name_yn === 'Y';
    /**
     * 대표자 연락처 유무
     * @type {boolean}
     */
    this.ceoCellphoneYn = obj.ceo_cellphone_yn === 'Y';
    /**
     * 대표자 이메일 유무
     * @type {boolean}
     */
    this.ceoEmailYn = obj.ceo_email_yn === 'Y';
    /**
     * 대표자 최종학력 유무
     * @type {boolean}
     */
    this.ceoFinalEnducationYn = obj.ceo_final_enducation_yn === 'Y';
    /**
     * 대표자 주요이력 유무
     * @type {boolean}
     */
    this.ceoMajorCareerYn = obj.ceo_major_career_yn === 'Y';

    /**
     * 현재 직원 수 유무
     * @type {boolean}
     */
    this.employeeNumberYn = obj.employee_number_yn === 'Y';
    /**
     * 직원추가계획 유무
     * @type {boolean}
     */
    this.employeeAddTagYn = obj.employee_add_tag_yn === 'Y';
    /**
     * 신청구분 유무
     * @type {boolean}
     */
    this.applicationCategoryYn = obj.application_category_yn === 'Y';
    /**
     * 멤버구성 유무
     * @type {boolean}
     */
    this.memberConfigurationYn = obj.member_configuration_yn === 'Y';

    /**
     * 홈페이지/SNS 유무
     * @type {boolean}
     */
    this.companyHomepageUrlYn = obj.company_homepage_url_yn === 'Y';
    /**
     * 수상경력 유무
     * @type {boolean}
     */
    this.accAwardCareerInfoYn = obj.acc_award_career_info_yn === 'Y';
    /**
     * 보유 지적재산권 유무
     * @type {boolean}
     */
    this.accPropertyInfoYn = obj.acc_property_info_yn === 'Y';
    /**
     * 보유 허가 및 등록현황 유무
     * @type {boolean}
     */
    this.accLicenseInfoYn = obj.acc_license_info_yn === 'Y';
    /**
     * 미국법인설립여부
     * @type {boolean}
     */
    this.usCorpEstYn = obj.us_corp_est_yn === 'Y';

    /**
     * 기타 입력 유무
     * @type {boolean}
     */
    // this.companyEtcYn = obj.member_etc_yn === 'Y';
    /**
     * 기타 입력 제목
     * @type {string}
     */
    // this.companyEtcTitle = obj.member_etc_title;


    /** 멤버정보 ***************************************** */

    /**
     * 이름 유무
     * @type {boolean}
     */
    this.nameYn = obj.name_yn === 'Y';
    /**
     * 고용형태 유무
     * @type {boolean}
     */
    this.workerKindYn = obj.worker_kind_yn === 'Y';
    /**
     * 직책 유무
     * @type {boolean}
     */
    this.positionYn = obj.position_yn === 'Y';
    /**
     * 주요경력/학력 유무
     * @type {boolean}
     */
    this.careerYn = obj.career_yn === 'Y';
    /**
     * 연락처 유무
     * @type {boolean}
     */
    this.memberCellphoneYn = obj.member_cellphone_yn === 'Y';
    /**
     * 이메일 유무
     * @type {boolean}
     */
    this.memberEmailYn = obj.member_email_yn === 'Y';
    /**
     * MBTI 유무
     * @type {boolean}
     */
    this.mbtiYn = obj.member_mbti_yn === 'Y';
    /**
     * 백신 접종 유무
     * @type {boolean}
     */
    this.vaccineYn = obj.member_vaccin_yn === 'Y';
    /**
     * 보유스킬 스택 유무
     * @type {boolean}
     */
    this.skillYn = obj.member_skill_yn === 'Y';
    /**
     * 프로그래밍 활용 능력 유무
     * @type {boolean}
     */
    this.programingLevelYn = obj.member_programing_level_yn === 'Y';
    /**
     * 창업프로그램 참여경력 유무
     * @type {boolean}
     */
    this.accStartPgJoinExpYn = obj.acc_start_pg_join_exp_yn === 'Y';
    /**
     * 주요 멘토 유무
     * @type {boolean}
     */
    this.accMentorInfoYn = obj.acc_mentor_info_yn === 'Y';
    /**
     * 기타 입력 유무
     * @type {boolean}
     */
    // this.memberEtcYn = obj.member_etc_yn === 'Y';
    /**
     * 기타 입력 제목
     * @type {string}
     */
    // this.memberEtcTitle = obj.member_etc_title;


    /** 대표서비스 ***************************************** */

    /**
     * 서비스명 유무
     * @type {boolean}
     */
    this.serviceNameYn = obj.service_name_yn === 'Y';
    /**
     * 서비스 소개 유무
     * @type {boolean}
     */
    this.serviceIntroduceYn = obj.service_introduce_yn === 'Y';
    /**
     * 서비스 차별점 유무
     * @type {boolean}
     */
    this.serviceDiffrenceYn = obj.service_diffrence_yn === 'Y';
    /**
     * 서비스/채널 URL 유무
     * @type {boolean}
     */
    this.serviceUrlYn = obj.service_url_yn === 'Y';
    /**
     * SNS URL 유무
     * @type {boolean}
     */
    this.serviceFacebookUrlYn = obj.service_facebook_url_yn === 'Y';
    /**
     * 홍보영상/기사URL 유무
     * @type {boolean}
     */
    this.promotionUrl1Yn = obj.promotion_url1_yn === 'Y';


    /** 투자내용 ***************************************** */

    /**
     * 투자일시 유무
     * @type {boolean}
     */
    this.investDateYn = obj.invest_date_yn === 'Y';
    /**
     * 투자기관 유무
     * @type {boolean}
     */
    this.investOrgYn = obj.invest_org_yn === 'Y';
    /**
     * 투자금액 유무
     * @type {boolean}
     */
    this.investAmountYn = obj.invest_amount_yn === 'Y';
    /**
     * 라운드 유무
     * @type {boolean}
     */
    this.investRoundYn = obj.invest_round_yn === 'Y';


    /** 첨부파일 ***************************************** */

    /**
     * 사업자등록증 파일첨부 유무
     * @type {boolean}
     */
    this.comRegNoFileYn = obj.com_reg_no_file_yn === 'Y';
    /**
     * 사업자등록증 파일첨부 유의사항
     * @type {string}
     */
    this.comRegNoFileNote = obj.com_reg_no_file_note;

    /**
     * 주주명부 파일첨부 유무
     * @type {boolean}
     */
    this.stockholderFileYn = obj.stockholder_file_yn === 'Y';
    /**
     * 주주명부 파일첨부 유의사항
     * @type {string}
     */
    this.stockholderFileNote = obj.stockholder_file_note;

    /**
     * 등기부등본 파일첨부 유무
     * @type {boolean}
     */
    this.registerFileYn = obj.register_file_yn === 'Y';
    /**
     * 등기부등본 파일첨부 유의사항
     * @type {string}
     */
    this.registerFileNote = obj.register_file_note;

    /**
     * 벤처기업등록증 사본 파일첨부 유무
     * @type {boolean}
     */
    // this.ventureFileYn = obj.venture_file_yn === 'Y';
    /**
     * 벤처기업등록증 파일첨부 유의사항
     * @type {string}
     */
    // this.ventureFileNote = obj.venture_file_note;

    /**
     * 지적재산권 사본 파일첨부 유무
     * @type {boolean}
     */
    this.propertyFileYn = obj.property_file_yn === 'Y';
    /**
     * 지적재산권 사본 파일첨부 유의사항
     * @type {string}
     */
    this.propertyFileNote = obj.property_file_note;

    /**
     * 기타1 파일첨부 유무
     * @type {boolean}
     */
    this.etcFileYn = obj.etc_file_yn === 'Y';
    /**
     * 기타1 파일첨부 제목
     * @type {string}
     */
    this.etcFileTitle = obj.etc_file_title;
    /**
     * 기타1 파일첨부 유의사항
     * @type {string}
     */
    this.etcFileNote = obj.etc_file_note;

    /**
     * 기타2 파일첨부 유무
     * @type {boolean}
     */
    this.etcFile2Yn = obj.etc_file2_yn === 'Y';
    /**
     * 기타2 파일첨부 제목
     * @type {string}
     */
    this.etcFileTitle2 = obj.etc_file_title2;
    /**
     * 기타2 파일첨부 유의사항
     * @type {string}
     */
    this.etcFileNote2 = obj.etc_file_note2;


    /** 기타 ***************************************** */

    /**
     * 지원항목 선택 유무
     * @type {boolean}
     */
    this.codeSupportItemYn = obj.code_support_item_yn === 'Y';
    /**
     * 지원동기 및 포부 유무
     * @type {boolean}
     */
    this.supportPlanYn = obj.support_plan_yn === 'Y';
    /**
     * 지원경로 유무
     * @type {boolean}
     */
    this.supportPathYn = obj.support_path_yn === 'Y';

    /**
     * 기타입력1 유무
     * @type {boolean}
     */
    this.etcInputYn = obj.etc_input_yn === 'Y';
    /**
     * 기타입력1 제목
     * @type {string}
     */
    this.etcInputTitle = obj.etc_input_title;
    /**
     * 기타입력1 내용
     * @type {string}
     */
    this.etcInputNote = obj.etc_input_note;

    /**
     * 기타입력2 유무
     * @type {boolean}
     */
    this.etcInput2Yn = obj.etc_input2_yn === 'Y';
    /**
     * 기타입력2 제목
     * @type {string}
     */
    this.etcInputTitle2 = obj.etc_input_title2;
    /**
     * 기타입력2 내용
     * @type {string}
     */
    this.etcInputNote2 = obj.etc_input_note2;


    /**
     * 기타입력2 유무
     * @type {boolean}
     */
    this.etcInput2Yn = obj.etc_input2_yn === 'Y';
    /**
    * 기타입력2 제목
    * @type {string}
    */
    this.etcInputTitle2 = obj.etc_input_title2;
    /**
    * 기타입력2 내용
    * @type {string}
    */
    this.etcInputNote2 = obj.etc_input_note2;
    /**
    * 기타입력3 유무
    * @type {boolean}
    */
    this.etcInput3Yn = obj.etc_input3_yn === 'Y';
    /**
     * 기타입력2 제목
     * @type {string}
     */
    this.etcInputTitle3 = obj.etc_input_title3;
    /**
     * 기타입력2 내용
     * @type {string}
     */
    this.etcInputNote3 = obj.etc_input_note3;
    /**
    * 기타입력4 유무
    * @type {boolean}
    */
    this.etcInput4Yn = obj.etc_input4_yn === 'Y';
    /**
     * 기타입력2 제목
     * @type {string}
     */
    this.etcInputTitle4 = obj.etc_input_title4;
    /**
     * 기타입력2 내용
     * @type {string}
     */
    this.etcInputNote4 = obj.etc_input_note4;

    /**
     * 기타입력4 유무
    * @type {boolean}
    */
    this.etcInput5Yn = obj.etc_input5_yn === 'Y';
    /**
    * 기타입력2 제목
    * @type {string}
    */
    this.etcInputTitle5 = obj.etc_input_title5;
    /**
    * 기타입력2 내용
    * @type {string}
    */
    this.etcInputNote5 = obj.etc_input_note5;
    /** 프로그램 참가서약서 ***************************************** */

    /**
     * 프로그램 참가서약서 표시 유무
     * @type {boolean}
     */
    this.entryAgreeYn = obj.entry_agree_yn === 'Y';

    /**
     * 프로그램 참가서약서
     * @type {string}
     */
    this.entryAgreeContents = obj.entry_agree_contents;


    /** 상태 ***************************************** */

    /**
     * 모집 중지 유무
     * @type {boolean}
     */
    this.postStopYn = obj.post_stop_yn === 'Y';

    this.member_gender = obj.member_gender === 'Y';
  }

  /**
   * 1단계 : 회사정보에서 입력할 것이 없는지 여부
   */
  isEmptyStep1() {
    return !(this.companyNameYn || this.companyNameEnYn
      || this.companyRegNoYn || this.corporationRegNoYn
      || this.ventureCertNoYn || this.buildYearYn
      || this.ceoNameYn || this.ceoCellphoneYn
      || this.ceoEmailYn || this.ceoFinalEnducationYn
      || this.ceoMajorCareerYn
      || this.employeeNumberYn || this.employeeAddTagYn
      || this.applicationCategoryYn || this.memberConfigurationYn
      || this.companyHomepageUrlYn || this.accAwardCareerInfoYn
      || this.accPropertyInfoYn || this.accLicenseInfoYn
      || this.usCorpEstYn
    );
  }

  /**
   * 2단계 : 멤버정보에서 입력할 것이 없는지 여부
   */
  isEmptyStep2() {
    return !(this.nameYn || this.workerKindYn
      || this.positionYn || this.careerYn
      || this.memberCellphoneYn || this.memberEmailYn
      || this.accStartPgJoinExpYn
      || this.accMentorInfoYn || this.member_gender
    );
  }

  /**
   * 3단계 : 대표서비스에서 입력할 것이 없는지 여부
   */
  isEmptyStep3() {
    return !(this.serviceNameYn || this.serviceIntroduceYn
      || this.serviceDiffrenceYn || this.serviceUrlYn
      || this.serviceFacebookUrlYn || this.promotionUrl1Yn
    );
  }

  /**
   * 4단계 : 투자내용에서 입력할 것이 없는지 여부
   */
  isEmptyStep4() {
    return !(this.investDateYn || this.investOrgYn
      || this.investAmountYn || this.investRoundYn
    );
  }

  /**
   * 5단계 : 기타입력에서 입력할 것이 없는지 여부
   * - (AC102) Lifestyle-Digital Healthcare / Wellness인 경우 : 지원영역(accInfo.acc_addition_kind) 필수입력
   */
  isEmptyStep5() {
    return !(this.comRegNoFileYn || this.stockholderFileYn
      || this.registerFileYn || this.propertyFileYn
      || this.etcFileYn || this.etcFile2Yn
      || this.codeSupportItemYn || this.supportPlanYn
      || this.supportPathYn
      || this.etcInputYn || this.etcInput2Yn || this.etcInput3Yn || this.etcInput4Yn || this.etcInput5Yn
      || this.entryAgreeYn
    )
    && this.accKind !== 'AC102';
  }
}
